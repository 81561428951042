// React imports
import React from 'react';

// UI – style imports
import { WithStyles, withStyles } from '@material-ui/core';
import { Motion, spring, presets } from 'react-motion';
import Box from '@material-ui/core/Box';
import Fade from '@material-ui/core/Fade';

// local imports
import { styles } from './Carousel.style';

type CarouselProps = typeof Carousel.defaultProps & {
    carouselSlides: React.ReactNode[];
    dotStyle: string;
    turnDuration?: number;
};

type IState = {
    currentSlide: number;
    currentPage: number;
    timerId: number;
    totalPages: number;
};

class Carousel extends React.Component<
    WithStyles<typeof styles> & CarouselProps, IState
> {
    static defaultProps = {
        contentWidth: 704,
        dataPerPage: 6,
    };

    constructor(props) {
        super(props);

        this.state = {
            currentSlide: 0,
            currentPage: 1,
            timerId: null,
            totalPages: 1,
        };

        this.handleClick = this.handleClick.bind(this);
        this.handleCarouselTurn = this.handleCarouselTurn.bind(this);
        this.checkChangeOfPages = this.checkChangeOfPages.bind(this);
        this.getPointIndexes = this.getPointIndexes.bind(this);
    }

    componentDidMount() {
        this.setState({
            totalPages: Math.ceil(this.props.carouselSlides.length / this.props.dataPerPage)
        });

        if (this.props.turnDuration) {
            const timerId = setInterval(
                () => this.handleCarouselTurn(timerId),
                this.props.turnDuration
            );
            this.setState({ timerId });
        }
    }

    componentWillUnmount() {
        const { timerId } = this.state;
        clearInterval(timerId);
    }

    handleClick(to: number) {
        this.setState(prevState => ({
            currentSlide: to,
            currentPage: this.checkChangeOfPages(to, prevState.currentPage)
        }));
    }

    handleCarouselTurn(timerId: number) {
        const newState = { ...this.state };
        const { carouselSlides } = this.props;
        let cycles = 0;

        if (cycles === 10) clearInterval(timerId);

        if (newState.currentSlide + 1 >= carouselSlides.length) {
            cycles += 1;
            newState.currentSlide = 0;
        } else {
            newState.currentSlide += 1;
        }

        newState.currentPage = this.checkChangeOfPages(
            newState.currentSlide,
            newState.currentPage
        );

        this.setState({ ...newState });
    }

    checkChangeOfPages(currentSlide: number, currentPage: number) {
        const { dataPerPage } = this.props;
        const { totalPages } = this.state;
        // Caso base para volver al inicio
        if (currentSlide === 0) return 1;
        if (currentPage == totalPages) return currentPage;

        // Si el cambio es de lado derecho avanzar de pagina
        if (currentSlide === (dataPerPage - 1) * currentPage) return currentPage + 1;

        // Si el cambio es de lado izquierdo retroceder de pagina
        if (
            currentSlide === (dataPerPage - 1) * (currentPage - 1) &&
            currentSlide !== 0
        )
            return currentPage - 1;

        return Math.ceil((currentSlide + 1) / dataPerPage);
    }

    getPointIndexes() {
        const { currentPage } = this.state;
        const { dataPerPage } = this.props;

        const auxArray = [...Array(dataPerPage).keys()];
        const indexArray = auxArray.map(
            x => x + (dataPerPage - 1) * (currentPage - 1)
        );
        return indexArray;
    }

    render() {
        const {
            currentSlide,
            currentPage,
            totalPages,
        } = this.state;

        const {
            classes,
            carouselSlides,
            dotStyle,
            contentWidth,
            dataPerPage,
        } = this.props;

        const indexArray = this.getPointIndexes();
        const leftStartCoords = -1 * currentSlide * contentWidth;

        let configs: { left: number }[] = [];
        carouselSlides.reduce((prevLeft: number) => {
            configs.push({
                left: spring(prevLeft)
            });
            return prevLeft + contentWidth;
        }, leftStartCoords);

        return (
            <Box className={classes.root}>
                <Box id={`dots-${dotStyle}`}>
                    {indexArray.map(index => {
                        if (currentPage === totalPages && index >= carouselSlides.length)
                            return null;

                        return (
                            <span
                              className={classes.dots}
                                key={`dots-${dotStyle}-${index}`}
                                onClick={() => this.handleClick(index)}
                                style={{
                                    marginLeft: '15px',
                                    cursor: 'pointer',
                                    fontSize:
                                        (index === (dataPerPage - 1) * currentPage ||
                                        (index === (dataPerPage - 1) * (currentPage - 1) &&
                                            index !== 0)) && currentPage != totalPages
                                        ? '20px'
                                        : '25px',
                                    color: currentSlide === index ? '#3E4375' : '#DEE0F2',
                                    textShadow: dotStyle === 'payments' ? '0 3px 3px #808080' : ''
                                }}
                            >
                                &#9679;
                            </span>
                        );
                    })}
                </Box>

                {configs.map((container, i) => (
                    <Motion key={`motion-transition-${i}-${container.left}`} style={container}>
                        {(style) => (
                            (carouselSlides[i] as ((props) => React.ReactNode))(style)
                        )}
                    </Motion>
                ))}
            </Box>
        );
    }
}

export default withStyles(styles)(Carousel);
