// React imports
import React from 'react';

// UI – style imports
import { WithStyles, withStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';

import recibirGif from 'images/gifs/Web_Tarjetas_Cuenca.gif';

import { styles } from './Benefits.style'

function Benefits(props: WithStyles<typeof styles>) {
    const { classes } = props;

    return (
        <Box className={classes.gridContainer}>
            <Box className={classes.textContainer}>
                <p className={classes.subtitle}>
                    Futuros <br/>beneficios
                </p>
                <p className='header'>MESES SIN INTERESES</p>
                <p className={classes.text}>
                    Porque no queremos que pagues más de lo que debes pagar,
                    te ofreceremos en un futuro Meses Sin Intereses.
                    Con el cual podrás diferir tus compras y pagarlas
                    en el tiempo que tu desees. En este servicio, con
                    tu autorización, consultaremos al Buró de Crédito.
                    Meses Sin Intereses dependerá del uso que hagas de
                    tu cuenta y de tus tarjetas física y virtual.
                </p>
                <p className='header'>LÍNEA DE CRÉDITO</p>
                <p className={classes.text}>
                    También en un futuro te brindaremos una línea de crédito
                    mayor al monto de tu saldo, para esas emergencias o
                    bien para un viaje. En este servicio, con tu
                    autorización, consultaremos al Buró de Crédito.
                    La línea de crédito dependerá del uso que hagas de
                    tu cuenta y de tus tarjetas física y virtual.
                </p>
            </Box>
            <Box id='benefits'>
              <img src={recibirGif} alt='Pasos para recibir y enviar Cuenca' />
            </Box>
        </Box>
    )
}

export default withStyles(styles)(Benefits);
