// React imports
import React from 'react';

// gatbsy imports
import { PageProps, graphql } from 'gatsby';

// SEO
import SEO from 'components/seo';

// Theme and layout
import WithTheme from 'components/WithTheme';
import Layout from 'views/Layout';

// Page views
import Cards from 'views/Cards';

type DataProps = {
    site: {
        buildTime: string;
    }
};

const TarjetasPage: React.FC<PageProps<DataProps>> = ({ data, path, location }) => (
    <WithTheme>
        <Layout location={location}>

            <SEO title='Tarjetas' />

            <Cards />

        </Layout>
    </WithTheme>
);

export default TarjetasPage;

export const query = graphql`
  {
    site {
      buildTime(formatString: "YYYY-MM-DD hh:mm a z")
    }
  }
`;
