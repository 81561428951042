// React imports
import React, { useState, useEffect } from 'react';

// UI – style imports
import { WithStyles, withStyles } from '@material-ui/core';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';


import dashTarjeta from 'images/web_Tarjetas_Cuenca.jpg';
import { styles } from './CardsBenefits.style'

function CardsBenefits(props: WithStyles<typeof styles>) {
    const { classes } = props;
    const [showSafariMargin, setMargin] = useState(false);

    return (
      <Box className={clsx(classes.root, showSafariMargin && 'safari-margin')}>
        <Box id='phoneCard'>
            <img src={dashTarjeta} alt='Tarjeta y app Cuenca' />
        </Box>
        <Box className={classes.textContainer}>
          <Box className={classes.titleContainer}>
              <p id='title'>Los beneficios</p>
              <p id='title'>de tus tarjetas</p>
              <p id='title'>física y virtual</p>
          </Box>
          <p id='text'>
          Las tarjetas te ofrecen un producto diferente en comparación con 
          los productos de débito. Te brindan mejores beneficios como, 
          generar un historial crediticio positivo, rentar autos, 
          reservar hoteles. Y con la posibilidad de acceder en un futuro 
          a Meses Sin Intereses y una línea de crédito. A diferencia de 
          los productos  de débito, nuestras tarjetas no requieren pago 
          de comisiones por apertura y anualidad, ni mantener un saldo mínimo.
          </p>
        </Box>
      </Box>
    )
}

export default withStyles(styles)(CardsBenefits);
