// React imports
import React from 'react';

// UI – style imports
import {
    WithStyles,
    withStyles,
    useMediaQuery
} from '@material-ui/core';
import Box from '@material-ui/core/Box';

// local imports
import { styles } from './CardCommissions.style';

function Fees(props: WithStyles<typeof styles>) {
    const { classes } = props;

    const xxlDesktop = useMediaQuery('(min-width:1600px)');
    const xlDesktop = useMediaQuery('(min-width:1360px)');
    const lgDesktop = useMediaQuery('(min-width:1230px)');
    const mdDesktop = useMediaQuery('(min-width:1000px)');
    const smDesktop = useMediaQuery('(min-width:769px)');
    const smallerDots = useMediaQuery('(max-width:769px)');
    const reallySmall = useMediaQuery('(max-width:401px)');

    return (
        <Box className={classes.root}>
            <Box className={classes.topContainer}>
                <Box className={classes.titleContainer}>
                    <p id='subtitle'>Comisiones<br></br>de las tarjetas</p>
                </Box>
                <p className={classes.topText}>
                    Con las tarjetas virtual y física no
                    hay anualidades, saldo mínimo, comisión
                    por adelanto de efectivo en cajero,
                    ni cobros por manejo de cuenta. Si perdiste
                    tu tarjeta física o ésta sufrió un daño
                    puedes solicitar una reposición con costo de $100 pesos,
                    la cual será con el diseño clásico. Sin embargo,
                    a partir de la segunda reposición
                    el costo es de $250 pesos.
                </p>
            </Box>
            <Box className={classes.midContainer}>
                <Box className={classes.comisionContainer}>
                    <Box className={classes.comision}>
                        <p className='comisionText'>
                            Anualidad
                            {reallySmall && '..'}
                            {smallerDots && '...............................'}
                            {smDesktop && '............................................................'}
                            {mdDesktop && '.............................'}
                            {lgDesktop && '............................'}
                            {xlDesktop && '................'}
                            {xxlDesktop && '...............................'}
                        </p>
                        <Box className={classes.rightNumber}>
                            <p className='ligthText'>$ 0</p>
                        </Box>
                    </Box>
                      <br/><br/><br/>
                    <p className='header'>TARJETA FÍSICA</p>
                    <br/>
                    <Box className={classes.comision}>
                        <p className='comisionText'>
                            Primera reposición
                            {reallySmall && '..'}
                            {smallerDots && '.................'}
                            {smDesktop && '............................................'}
                            {mdDesktop && '.............................'}
                            {lgDesktop && '............................'}
                            {xlDesktop && '................'}
                            {xxlDesktop && '...............................'}
                        </p>
                        <Box className={classes.rightNumber}>
                            <p className='ligthText'>$ 100</p>
                        </Box>
                    </Box>
                    <Box className={classes.comision}>
                        <p className='comisionText'>
                            A partir de la{smallerDots ? <br/> : null}segunda reposición
                            {reallySmall && '..'}
                            {smallerDots && '................'}
                            {smDesktop && '.....................'}
                            {mdDesktop && '.............................'}
                            {lgDesktop && '............................'}
                            {xlDesktop && '................'}
                            {xxlDesktop && '...............................'}
                        </p>
                        <Box className={classes.rightNumber}>
                            <p className='ligthText'>$ 250</p>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <p className={classes.bottomText}>
                En un futuro te brindaremos una línea de
                crédito por lo que estas comisiones pueden cambiar.
            </p>

        </Box>
    );
}

export default withStyles(styles)(Fees);
