import { Theme, createStyles } from '@material-ui/core/styles';


export const styles = (theme: Theme) => createStyles({
  root: {
    fontFamily: 'MuseoSans, sans-serif',
    margin: '0 0 17em 28% ',
    display: 'grid',
    gridTemplateColumns: '37% 63%',
    gridTemplateRows: 'auto auto auto auto auto',
    '& #dots-undefined': {
      position: 'absolute',
      left: '8.25vw',
      bottom: '1vh',
      zIndex: 1,
    },
    [theme.breakpoints.down(1700)]: {
      '& #dots-undefined': {left: '9vw'},
    },
    [theme.breakpoints.down(1250)]: {
      '& #dots-undefined': {left: '10vw'},
    },
    [theme.breakpoints.down(1200)]: {
        margin:'0 0 17em 23%',
        gridTemplateColumns: '40.5% 59.5%',
    },
    [theme.breakpoints.down(1050)]: {
      '& #dots-undefined': {left: '11.5vw'},
    },
    [theme.breakpoints.down(1001)]: {
      margin:'0 0 17em 20%',
    },
    [theme.breakpoints.down(950)]: {
      '& #dots-undefined': {left: '13.5vw'},
    },
    [theme.breakpoints.down(905)]: {
      margin:'0 0 17em 16%',
      gridTemplateColumns: '43% 55%',
    },
    [theme.breakpoints.down(850)]: {
      gridTemplateColumns: '48% 52%',
    },
    [theme.breakpoints.down(850)]: {
      margin:'0 0 12em 16%',
      '& #dots-undefined': {left: '15vw'},
    },
    [theme.breakpoints.down(769)]: {
      display:'flex',
      flexDirection:'column',
      margin: '100px 20% 150px 25% ',
      '& #dots-undefined': {
        marginLeft:'10%',
        left: '10vw'
      },
    },
    [theme.breakpoints.down(700)]: {
      '& #dots-undefined': {left: '3vw'},
    },
    [theme.breakpoints.down(601)]: {
      margin: '100px 20% 150px 23% ',
      '& #dots-undefined': {bottom:'0vh'},
    },
    [theme.breakpoints.down(500)]: {
      margin: '-200px 10% 120px 23% ',
      '& #dots-undefined': {marginLeft:'5%'},
    },
    [theme.breakpoints.down(400)]: {
      margin: '-200px 5% 100px 23% ',
      '& #dots-undefined': {
        marginLeft:'5%',
        left: '5vw',
      },
    },
  },
  inline: {
    fontWeight: 900,
    fontSize: '18.31px',
    letterSpacing: '1.71px',
    color: theme.palette.primary.gray,
    position: 'absolute',
    right: '20vw',
    display:'inline-flex',
    '& .left': {
      marginRight: '6vw',
    },
    '& .active': {
      color: theme.palette.primary.dark,
    },
    '& p': {
      cursor: 'pointer',
    },
    [theme.breakpoints.down(2100)]: {right: '19vw',},
    [theme.breakpoints.down(1850)]: {right: '17vw',},
    [theme.breakpoints.down(1550)]: {right: '15.5vw',},
    [theme.breakpoints.down(1440)]: {right: '13vw',},
    [theme.breakpoints.down(1300)]: {right: '10vw',},
    [theme.breakpoints.down(990)]: {right: '7vw',},
    [theme.breakpoints.down(910)]: {right: '6vw',},
    [theme.breakpoints.down(890)]: {right: '5vw',},
    [theme.breakpoints.down(860)]: {right: '4.5vw',},
    [theme.breakpoints.down(769)]: {right: '27vw',},
    [theme.breakpoints.down(720)]: {right: '22.5vw',},
    [theme.breakpoints.down(700)]: {right: '33vw',},
    [theme.breakpoints.down(600)]: {right: '26vw', marginTop:'10px'},
    [theme.breakpoints.down(450)]: {right: '21vw'},
    [theme.breakpoints.down(400)]: {
      right: '18vw',
      fontSize: '11px',
      letterSpacing: '0.93px',
      '& .left': {
        marginRight: '13vw',
      },
    },
    [theme.breakpoints.down(330)]: {right: '11.5vw'},
    [theme.breakpoints.down(290)]: {
      right: '11vw',
      '& .left': {marginRight: '11vw'}
    },
  },
  textContainer: {
    [theme.breakpoints.down(769)]: {marginLeft:'20%'},
  },
  carousel: {
    marginRight:'27%',
    overflow: 'hidden',
    height: '700px',
    [theme.breakpoints.down(1101)]: {
      marginRight:'21%',
    },
    [theme.breakpoints.down(1001)]: {
      marginRight:'10%',
    },
    [theme.breakpoints.down(850)]: {
      height: '640px',
      marginRight:'0%',
    },
    [theme.breakpoints.down(701)]: {
      height: '690px',
      marginTop:'20%',
    },
    [theme.breakpoints.down(550)]: {
      height: '590px',
    },
    [theme.breakpoints.down(450)]: {
      height: '530px',
    },
    [theme.breakpoints.down(400)]: {
      height: '475px',
    },
    [theme.breakpoints.down(350)]: {
      height: '400px',
    },
  },
  subtitle: {
    fontWeight: 700,
    fontSize: '18.31px',
    letterSpacing: '1.71px',
    lineHeight: '29px',
    color: '#9BA0B7',
    marginBottom: '40px',
    [theme.breakpoints.down(1200)]: {
        marginBottom: '70px',
    },
    [theme.breakpoints.down(850)]: {
      marginBottom:'50px',
    },
    [theme.breakpoints.down(768)]: {
      lineHeight: '25px',
      letterSpacing: '0.93px',
    },
    [theme.breakpoints.down(751)]: {
      fontSize: '16px',
    },
    [theme.breakpoints.down(651)]: {
      fontSize: '14px',
      lineHeight: '22px',
    },
    [theme.breakpoints.down(551)]: {
      fontSize: '12px',
      lineHeight: '20px',
    },
    [theme.breakpoints.down(501)]: {
      fontSize: '11px',
      lineHeight: '18px',
    },
    [theme.breakpoints.down(401)]: {
      fontSize: '10.86px',
      lineHeight: '16px',
      marginBottom:'30px',
    }
  },
  text: {
    fontWeight:500,
    fontSize: '18.3px',
    letterSpacing: '0.7px',
    lineHeight: '26.35px',
    width:'95%',
    marginBottom:'80px',
    color: theme.palette.primary.main,
    [theme.breakpoints.down(768)]: {
      fontSize: '21px',
      lineHeight: '30px',
      letterSpacing: '0.47px',
    },
    [theme.breakpoints.down(751)]: {fontSize: '16px'},
    [theme.breakpoints.down(651)]: {
      fontSize: '14px',
      lineHeight: '22px'
    },
    [theme.breakpoints.down(551)]: {
      fontSize: '12px',
      lineHeight: '20px'
    },
    [theme.breakpoints.down(501)]: {
      fontSize: '11px',
      lineHeight: '18px'
    },
    [theme.breakpoints.down(451)]: {
      fontSize: '10.86px'
    },
    [theme.breakpoints.down(401)]: {
      fontSize: '10.86px',
      lineHeight: '16px',
      marginBottom:'50px',
    }
  },
});
