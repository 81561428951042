import { Theme, createStyles } from '@material-ui/core/styles';

export const styles = (theme: Theme) => createStyles({
    gridContainer: {
      fontFamily: 'MuseoSans, sans-serif',
      marginBottom: '250px',
      marginTop:'250px',
      marginLeft: '30%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent:'space-between',
      alignItems:'flex-end',
      '& #benefits': {
        height: '579px',
        width: '363px',
          '& img': {
            height: '579px',
            width: '363px',
            marginBottom:'0px',
          },
      },
      [theme.breakpoints.down(769)]: {
        flexDirection: 'column',
        marginBottom: '0px',
        marginTop:'207px',
        marginLeft: '37.5%',
        alignItems:'flex-start',
        '& #benefits': {
          marginTop:'143px',
          width: '426px',
          '& img': {
            width: '426px !important',
          },
        },
      },
      [theme.breakpoints.down(700)]: {
        '& #benefits': {
          width: '426px',
          '& img': {
            width: '356px !important',
            height: '529px !important',
          },
        },
      },
      [theme.breakpoints.down(651)]: {
        marginLeft: '32%',
      },
      [theme.breakpoints.down(601)]: {
        '& #benefits': {
          width: '350px',
          '& img': {
            width: '350px !important',
          },
        },
      },
      [theme.breakpoints.down(551)]: {
        marginLeft: '27%',
      },
      [theme.breakpoints.down(501)]: {
        '& #benefits': {
          width: '300px',
          height: '460px',
          '& img': {
              width: '275px !important',
              height: '413px !important',
          },
        },
      },
      [theme.breakpoints.down(401)]: {
        marginTop:'107px',
        '& #benefits': {
          marginTop:'80px',
          width: '224px',
          height: '500px',
          '& img': {
            width: '224px !important',
            height: '379px !important',
          },
        },
      },
      [theme.breakpoints.down(351)]: {
        marginLeft: '22%',
      },
    },
    textContainer: {
        width: '48%',
        '& .header': {
            fontSize: '18.33px',
            fontWeight: 700,
            color: theme.palette.primary.gray,
            lineHeight:'22px',
            letterSpacing: '0.93px',
            marginBottom: '40px',
            marginTop:'90px',
        },
        [theme.breakpoints.down(1100)]: {
          '& .header': {
            marginBottom: '38px',
            marginTop:'85px',
          },
        },
        [theme.breakpoints.down(1060)]: {
          '& .header': {
            marginBottom: '34px',
            marginTop:'80px',
          },
        },
        [theme.breakpoints.down(1000)]: {
          '& .header': {
            marginBottom: '32px',
            marginTop:'75px',
          },
        },
        [theme.breakpoints.down(900)]: {
          '& .header': {
            marginBottom: '30px',
            marginTop:'70px',
          },
        },
        [theme.breakpoints.down(769)]: {
          width: '81%',
          '& .header': {
            marginBottom: '73px',
            marginTop:'151px',
          },
        },
        [theme.breakpoints.down(751)]: {
          '& .header': {
            marginTop:'135px',
            fontSize: '16px',
          },
        },
        [theme.breakpoints.down(651)]: {
          '& .header': {
            marginBottom: '68px',
            marginTop:'120px',
            fontSize: '14px',
            lineHeight: '22px',
          },
        },
        [theme.breakpoints.down(551)]: {
          '& .header': {
            marginTop:'100px',
            fontSize: '12px',
            lineHeight: '20px',
          },
        },
        [theme.breakpoints.down(501)]: {
          '& .header': {
            marginBottom: '63px',
            marginTop:'80px',
            fontSize: '11px',
            lineHeight: '18px',
          },
        },
        [theme.breakpoints.down(451)]: {
          '& .header': {
            marginTop:'50px',
          },
        },
        [theme.breakpoints.down(401)]: {
          '& .header': {
            fontSize: '10.9px',
            lineHeight: '16px',
            marginBottom: '60px',
            marginTop:'39px',
          },
        },
    },
    subtitle: {
        fontSize: '36px',
        color: '#3D4274',
        letterSpacing: '0.89px',
        lineHeight: '50px',
        fontWeight: 300,
        marginBottom: '10px',
        [theme.breakpoints.down(751)]: {
          fontSize: '32px',
          lineHeight: '33px',
        },
        [theme.breakpoints.down(651)]: {
          fontSize: '29px',
          lineHeight: '30px',
        },
        [theme.breakpoints.down(551)]: {
          fontSize: '25px',
        },
        [theme.breakpoints.down(501)]: {
          fontSize: '23px',
          lineHeight: '27px',
        },
        [theme.breakpoints.down(451)]: {
          fontSize: '21px',
          lineHeight: '25px',
        },
        [theme.breakpoints.down(401)]: {
          fontSize: '19.43px',
          lineHeight: '22.8px',
        },
    },
    text: {
        fontSize: '18.3px',
        color: '#3D4274',
        letterSpacing: '0.75px',
        lineHeight: '26.35px',
        fontWeight: 500,
        margin:'0',
        [theme.breakpoints.down(1100)]: {
          fontSize: '18px',
        },
        [theme.breakpoints.down(1060)]: {
          fontSize: '17.5px',
        },
        [theme.breakpoints.down(1000)]: {
          fontSize: '17px',
        },
        [theme.breakpoints.down(900)]: {
          fontSize: '16.5px',
        },
        [theme.breakpoints.down(800)]: {
          fontSize: '16.2px',
        },
        [theme.breakpoints.down(751)]: {
          fontSize: '16px',
        },
        [theme.breakpoints.down(651)]: {
          fontSize: '14px',
          lineHeight: '22px',
        },
        [theme.breakpoints.down(551)]: {
          fontSize: '12px',
          lineHeight: '20px',
        },
        [theme.breakpoints.down(501)]: {
          fontSize: '11px',
          lineHeight: '18px',
        },
        [theme.breakpoints.down(401)]: {
          fontSize: '10.86px',
          lineHeight: '16px',
        },
    },
});
