// React imports
import React from 'react';

// UI – style imports
import { WithStyles, withStyles, useMediaQuery,useTheme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
// local imports
import { styles } from './Cards.style';
import CardsHero from './CardsHero';
import CardsBenefits from './CardsBenefits';
import CardCommissions from './CardCommissions';
import CardsIntro from './CardsIntro';
import CashWithdrawals from './CashWithdrawals';
import CardsCarousel from './CardsCarousel';
import Alliance from './Alliance';
import Benefits from './Benefits';

function Cards(props: WithStyles<typeof styles>) {
    const { classes } = props;

    return (
        <Box className={classes.root}>
          <CardsHero />
          <CardsIntro />
          <CardsBenefits />
          <CardsCarousel />
          <Benefits />
          <CardCommissions />
          <CashWithdrawals />
          <Alliance />
        </Box>
    );
}

export default withStyles(styles)(Cards);
