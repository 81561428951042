import { Theme, createStyles } from '@material-ui/core/styles';


export const styles = (theme: Theme) => createStyles({
    root: {
      fontFamily: 'MuseoSans, sans-serif',
      margin: '390px 0 17em 28%',
      display: 'grid',
      gridTemplateColumns: '30% 80%',
      gridTemplateRows: 'auto auto auto auto auto',
      [theme.breakpoints.down(769)]: {
        display:'flex',
        flexDirection:'column',
        margin: '110px 0 0px 22%',
      },
  },
    gridContainer: {
        margin: '0px 0px 0px 170px',
        //margin: '0px 0px 250px 170px',
        padding: '73px 110px 90px 70px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent:'space-between',
        [theme.breakpoints.down(1200)]: {
              margin: '0px 0px 250px 20%',
              padding: '73px 58px 90px 40px',
        },
        [theme.breakpoints.down(769)]: {
          margin: ' 123px 0px 100px 0px',
          padding: '57px 0px 53px 65px',
        },
        [theme.breakpoints.down(551)]: {
          padding: '48px 0px 40px 49px',
          margin: ' 80px 0px 0px 0px',
        },
        [theme.breakpoints.down(401)]: {
          padding: '40px 0px 35px 43px',
          margin: ' 55px 0px 0px 0px',
        },
        [theme.breakpoints.down(356)]: {
          padding: '40px 0px 35px 23px',

        },
    },
    textContainer1: {
        width: '30%',
        alignSelf:'center',
        color: '#3D4274',
        '& .subtitle': {
            fontSize: '19px',
            fontWeight: 500,
            letterSpacing: '0.89px',
            lineHeight: '29px',
            margin:'0',
        },
        // [theme.breakpoints.down(1400)]: {
        //     fontSize: '18px',
        //     lineHeight: '28px',
        // },
        // [theme.breakpoints.down(1300)]: {
        //     fontSize: '17px',
        //     lineHeight: '27px',
        // },
        // [theme.breakpoints.down(1200)]: {
        //     fontSize: '16.5px',
        //     lineHeight: '26.5px',
        // },
        [theme.breakpoints.down(769)]: {
          alignSelf:'auto',
        },
        [theme.breakpoints.down(751)]: {
          '& .subtitle': {
              fontSize: '17px',
          },
        },
        [theme.breakpoints.down(651)]: {
          '& .subtitle': {
          fontSize:'15px',
          lineHeight: '22px',
          },
        },
        [theme.breakpoints.down(551)]: {
          '& .subtitle': {
          fontSize:'13px',
          lineHeight: '20px',
          },
        },
        [theme.breakpoints.down(501)]: {
          '& .subtitle': {
          fontSize:'11px',
          lineHeight: '20px',
          },
        },
        [theme.breakpoints.down(401)]: {
          '& .subtitle': {
              fontSize: '9.14px',
              lineHeight: '14.4px',
          },
        },
    },
    textContainer: {
      fontSize: '36px',
      color: theme.palette.primary.main,
      letterSpacing: '0.89px',
      lineHeight: '50px',
      fontWeight: 300,
      marginBottom:'0px',
      '& .subtitle': {
          marginBottom: '90px',
      },
      '& .text': {
          fontSize: '18.3px',
          fontWeight: 500,
          letterSpacing: '0.7px',
          lineHeight: '26.35px',
      },
      // [theme.breakpoints.down(1400)]: {
      //     fontSize: '17.33px',
      //     lineHeight: '25px',
      // },
      // [theme.breakpoints.down(1300)]: {
      //     fontSize: '16.33px',
      //     lineHeight: '24.5px',
      // },
      // [theme.breakpoints.down(1200)]: {
      //     fontSize: '15.33px',
      //     lineHeight: '24px',
      // },
      // [theme.breakpoints.down(1100)]: {
      //     fontSize: '14.33px',
      //     lineHeight: '23.5px',
      // },
      // [theme.breakpoints.down(1000)]: {
      //     fontSize: '14px',
      //     lineHeight: '23px',
      // },
      // [theme.breakpoints.down(900)]: {
      //     fontSize: '13.33px',
      // },
      [theme.breakpoints.down(769)]: {
        maxWidth:'65%',
        '& .text': {
            marginBottom:'0px',
        },
      },
      [theme.breakpoints.down(751)]: {
        fontSize: '29px',
        '& .subtitle': {
            fontSize: '32px',
            lineHeight: '33px',
        },
        '& .text': {
            fontSize: '16px',
        },
      },
      [theme.breakpoints.down(651)]: {
        fontSize: '26px',
        lineHeight: '40px',
        '& .subtitle': {
            fontSize: '29px',
            lineHeight: '30px',
        },
        '& .text': {
            fontSize: '14px',
            lineHeight: '22px',
        },
      },
      [theme.breakpoints.down(551)]: {
        fontSize: '22px',
        lineHeight: '30px',
        '& .subtitle': {
            fontSize: '25px',
        },
        '& .text': {
            fontSize: '12px',
            lineHeight: '20px',
        },
      },
      [theme.breakpoints.down(501)]: {
        fontSize: '20px',
        maxWidth:'70%',
        '& .subtitle': {
            fontSize: '23px',
            lineHeight: '27px',
            marginBottom: '70px',
        },
        '& .text': {
            fontSize: '11px',
            lineHeight: '18px',
        },
      },
      [theme.breakpoints.down(451)]: {
        fontSize: '18px',
        '& .subtitle': {
            fontSize: '21px',
            lineHeight: '25px',
        },
      },
      [theme.breakpoints.down(401)]: {
        fontSize: '16px',
        '& .subtitle': {
            fontSize: '19.43px',
            marginBottom: '62px',
            lineHeight: '22.8px',
        },
        '& .text': {
            fontSize: '10.86px',
            lineHeight: '16px',
        },
      },
    },
    textContainer2: {
        width: '60%',
        alignSelf:'center',
        color: '#3D4274',
        '& .text': {
            fontSize: '18.33px',
            letterSpacing: '0.7px',
            lineHeight: '26.35px',
            paddingRight: '60px',
        },
        // [theme.breakpoints.down(1400)]: {
        //     fontSize: '17.33px',
        //     lineHeight: '25px',
        // },
        // [theme.breakpoints.down(1300)]: {
        //     fontSize: '16.33px',
        //     lineHeight: '24.5px',
        // },
        // [theme.breakpoints.down(1200)]: {
        //     fontSize: '15.33px',
        //     lineHeight: '24px',
        // },
        // [theme.breakpoints.down(1100)]: {
        //     fontSize: '14.33px',
        //     lineHeight: '23.5px',
        // },
        // [theme.breakpoints.down(1000)]: {
        //     fontSize: '14px',
        //     lineHeight: '23px',
        // },
        // [theme.breakpoints.down(900)]: {
        //     fontSize: '13.33px',
        // },
        [theme.breakpoints.down(769)]: {
          '& .text': {
              paddingRight: '0px',
              maxWidth:'75%',
          },
        },
        [theme.breakpoints.down(751)]: {
          '& .text': {
              fontSize: '16px',
          },
        },
        [theme.breakpoints.down(651)]: {
          '& .text': {
              fontSize: '14px',
              lineHeight: '22px',
          },
        },
        [theme.breakpoints.down(551)]: {
          '& .text': {
              fontSize: '12px',
              lineHeight: '20px',
          },
        },
        [theme.breakpoints.down(501)]: {
          '& .text': {
              fontSize: '11px',
              lineHeight: '18px',
              maxWidth:'90%',
          },
        },
        [theme.breakpoints.down(401)]: {
          '& .text': {
              fontSize: '10.86px',
              lineHeight: '16px',
              marginBottom:'0px',
          },
        },
    },
    bigText: {
        fontSize: '120px',
        color: '#3D4274',
        letterSpacing: '0.75px',
        fontWeight: 700,
        marginBottom:'0px',
        [theme.breakpoints.down(751)]: {
          fontSize: '110px',
        },
        [theme.breakpoints.down(651)]: {
          fontSize: '100px',
        },
        [theme.breakpoints.down(551)]: {
          fontSize: '90px',
        },
        [theme.breakpoints.down(501)]: {
          fontSize: '80px',
        },
        [theme.breakpoints.down(451)]: {
          fontSize: '75px',
        },
        [theme.breakpoints.down(401)]: {
          fontSize: '68.57px',
        },
    },
    straightLine: {
        borderTop: '1px solid #3D4274',
        background: 'none !important',
        margin: '25px  0',
        [theme.breakpoints.down(401)]: {
          margin: '15px  0',
        },
    },
});
