// React imports
import React from 'react';

// UI – style imports
import { WithStyles, withStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';

import { styles } from './GifCarousel.style';

// Cards/CardsCarousel
import tarjeta_fisica_1 from './images/WS_appflows_fisica_1.gif';
import tarjeta_fisica_2 from './images/WS_appflows_fisica_2.gif';
import tarjeta_fisica_3 from './images/WS_appflows_fisica_3.gif';
import tarjeta_fisica_4 from './images/WS_appflows_fisica_4.gif';
import tarjeta_fisica_5 from './images/WS_appflows_fisica_5.gif';
import tarjeta_fisica_6 from './images/WS_appflows_fisica_6.gif';

import tarjeta_virtual_1 from './images/WS_appflows_virtual_1.gif';
import tarjeta_virtual_2 from './images/WS_appflows_virtual_2.gif';
import tarjeta_virtual_3 from './images/WS_appflows_virtual_3.gif';
import tarjeta_virtual_4 from './images/WS_appflows_virtual_4.gif';
import tarjeta_virtual_5 from './images/WS_appflows_virtual_5.gif';
import tarjeta_virtual_6 from './images/WS_appflows_virtual_6.gif';

type ImageCarouselProps = {
    type: string;
    style: object;
} & typeof defaultProps;

const defaultProps = {
    type: 'current_fisical_1',
};

const DATA_SOURCE = {
    'tarjeta_virtual_1': [tarjeta_virtual_1, 'ENTRA A “TUS TARJETAS” DESDE LA PANTALLA PRINCIPAL DE LA APP.'],
    'tarjeta_virtual_2': [tarjeta_virtual_2, 'ESPERA UN MOMENTO, TE REDIRECCIONAREMOS A TARJETAS CUENCA.'],
    'tarjeta_virtual_3': [tarjeta_virtual_3, 'PARA REALIZAR TU PAGO NECESITAS COPIAR TU NÚMERO DE TARJETA.'],
    'tarjeta_virtual_4': [tarjeta_virtual_4, 'RECUERDA QUE TAMBIÉN TE VAN A SOLICITAR LA FECHA DE EXPIRACIÓN DE TU TARJETA Y EL CÓDIGO DE VERIFICACIÓN.'],
    'tarjeta_virtual_5': [tarjeta_virtual_5, 'PUEDES CONSULTAR RÁPIDAMENTE LAS TRANACCIONES QUE HAS REALIZADO CON ESTA TARJETA.'],
    'tarjeta_virtual_6': [tarjeta_virtual_6, 'TAMBIÉN BLOQUEARLA Y DESBLOQUEARLA FÁCILMENTE.'],

    'tarjeta_fisica_1': [tarjeta_fisica_1, 'ENTRA A “TUS TARJETAS” DESDE LA PANTALLA PRINCIPAL DE LA APP.'],
    'tarjeta_fisica_2': [tarjeta_fisica_2, 'ESPERA UN MOMENTO, TE REDIRECCIONAREMOS A TARJETAS CUENCA.'],
    'tarjeta_fisica_3': [tarjeta_fisica_3, 'ENTRA A LA SECCIÓN “TARJETA FÍSICA”.'],
    'tarjeta_fisica_4': [tarjeta_fisica_4, 'PODRÁS CONSULTAR TU NIP EN CUALQUIER MOMENTO DESDE TU APP. ENTRA A “MI NIP” PARA VERLO'],
    'tarjeta_fisica_5': [tarjeta_fisica_5, 'POR SEGURIDAD, TE PEDIREMOS TU CONTRASEÑA ANTES DE MOSTRARTE TU NIP.'],
    'tarjeta_fisica_6': [tarjeta_fisica_6, '¡LISTO! USA EL NIP PARA HACER COMPRAS CON TU TARJETA FÍSICA. RECUERDA, EL NIP ES PERSONAL Y NO SE PUEDE CAMBIAR.'],
};

function GifCarousel(
    props: ImageCarouselProps & WithStyles<typeof styles>
) {
    const { classes, type, ...rest } = props;
    const data = DATA_SOURCE[type]

    return (
        <Box className={classes.root} {...rest}>
            <Box className={classes.image}>
                <img src={data[0]} alt='Random Image Placeholder' />
            </Box>

            <Box className={classes.description}>
                {data[1]}
            </Box>
        </Box>
    );
}
GifCarousel.defaultProps = defaultProps;

export default withStyles(styles)(GifCarousel);
