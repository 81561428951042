import { Theme, createStyles } from '@material-ui/core/styles';

export const styles = (theme: Theme) => createStyles({
    root: {
        fontFamily: 'MuseoSans, sans-serif',
        color: theme.palette.primary.main,
        width: '1329px',
        marginBottom: '325px',
        marginTop: '325px',
        display: 'flex',
        flexDirection: 'row',
        '& #phoneCard': {
            height: '100%',
            width: '704px',
            '& img': {
                height: '100%',
                width: '100%',
                objectFit: 'cover',
            },
        },
        [theme.breakpoints.down(1400)]: { width: '100%', },
        [theme.breakpoints.down(1000)]: {
            marginBottom: '200px',
            flexDirection: 'column',
            '& #phoneCard': {
                height: '575px',
                width: '100%',
            },
        },
        [theme.breakpoints.down(769)]: {
          marginBottom: '100px',
          marginTop: '100px',
          flexDirection:'column-reverse',
          '& #phoneCard': {
              width: '77%',
          },
        },
        [theme.breakpoints.down(651)]: {
          marginBottom: '90px',
        },
        [theme.breakpoints.down(601)]: {
          marginBottom: '80px',
        },
        [theme.breakpoints.down(551)]: {
          marginBottom: '70px',
        },
        [theme.breakpoints.down(501)]: {
          '& #phoneCard': {
              width: '100%',
              '& img': {
                  height: '66% !important',
              },
          },
        },
        [theme.breakpoints.down(451)]: {
          marginBottom: '60px',
        },
        [theme.breakpoints.down(401)]: {
          '& #phoneCard': {
              '& img': {
                  height: '52% !important',
              },
          },
        },
    },
    textContainer: {
        width: '425px',
        marginBottom: '123px',
        marginLeft: '13.89%',
        marginTop: '15px',
        '& #text': {
            fontSize: '18.33px',
            letterSpacing: '0.75px',
            color: '#3D4274',
            lineHeight: '26.35px',
            width: '323px',
        },
        [theme.breakpoints.down(769)]: {
          margin: '0px 0px 0px 22%',
          '& #text': {
              marginLeft: '22%',
              width: '100%',
              marginBottom:'180px',
          },
        },
        [theme.breakpoints.down(751)]: {
          '& #text': {
              fontSize: '16px',
              marginBottom:'150px',
              width: '94%',
          },
        },
        [theme.breakpoints.down(651)]: {
          '& #text': {
              fontSize: '14px',
              lineHeight: '22px',
              marginBottom:'120px',
              width: '75%',
              marginLeft: '17%',
          },
        },
        [theme.breakpoints.down(551)]: {
          '& #text': {
            fontSize: '12px',
            lineHeight: '20px',
            marginBottom:'100px',
            width: '68%',
            marginLeft: '12%',
          },
        },
        [theme.breakpoints.down(501)]: {
          '& #text': {
              fontSize: '11px',
              lineHeight: '18px',
              marginBottom:'90px',
          },
        },
        [theme.breakpoints.down(451)]: {
          '& #text': {
            width: '60%',
          },
        },
        [theme.breakpoints.down(401)]: {
          '& #text': {
              fontSize: '10.86px',
              lineHeight: '16px',
              marginBottom:'69px',
              width: '59%',
              marginLeft: '5%',
          },
        },
        [theme.breakpoints.down(351)]: {
          '& #text': {
              width: '52%',
              marginLeft: '0%',
          },
        },
    },
    titleContainer: {
        marginBottom: '120.65px',
        letterSpacing: '0.89px',
        lineHeight:'50px',
        fontWeight: 300,
        fontSize: '36px',
        '& p': {
          marginBottom: '0px',
        },
        [theme.breakpoints.down(769)]: {
          marginBottom: '90px',
          '& #title': {
            fontSize: '36px',
            lineHeight:'40px',
          },
        },
        [theme.breakpoints.down(751)]: {
          '& #title': {
              fontSize: '32px',
              lineHeight:'33px',
          },
        },
        [theme.breakpoints.down(651)]: {
          '& #title': {
              fontSize: '29px',
              lineHeight:'30px',
          },
        },
        [theme.breakpoints.down(601)]: {
          '& #title': {
              fontSize: '27px',
              lineHeight:'32px',
          },
        },
        [theme.breakpoints.down(551)]: {
          '& #title': {
              fontSize: '25px',
              lineHeight:'28px',
          },
        },
        [theme.breakpoints.down(501)]: {
          marginBottom: '70px',
          '& #title': {
              fontSize: '23px',
              lineHeight:'27px',
          },
        },
        [theme.breakpoints.down(451)]: {
          '& #title': {
              fontSize: '21px',
              lineHeight:'25px',
          },
        },
        [theme.breakpoints.down(401)]: {
          marginBottom: '62px',
          '& #title': {
            lineHeight:'22.8px',
            fontSize: '19.43px',
          },
        },
    },
});
