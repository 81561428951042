// React imports
import React from 'react';

// UI – style imports
import {
    WithStyles,
    withStyles,
    useMediaQuery
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

// local imports
import { styles } from './CashWithdrawals.style';

function Withdrawals(props: WithStyles<typeof styles>) {
    const { classes } = props;
    const smallerDots = useMediaQuery('(max-width:769px)');

    return (
        <Box className={classes.root}>
          <Box className={classes.topContainer}>
            <p className='header'>COMISIONES POR RETIROS DE EFECTIVO</p>
          </Box>
          <Grid container className={classes.container}>
              <Grid item xs={smallerDots?12:6} className={classes.textContainer}>
                <p className={classes.topText}>
                    Por retiro de efectivo en la red de cajeros
                    electrónicos nacionales e internacionales
                    afiliados a Mastercard no se cobra comisión;
                    sin embargo, cada institución bancaria tiene
                    sus comisiones por disposición de efectivo.
                </p>
                <p className={classes.topText}>
                    Las comisiones varían de acuerdo a los criterios
                    establecidos por cada entidad financiera; tales
                    como: ubicación, dentro o fuera de sucursales,
                    riesgo, giro comercial, donde se encuentra
                    instalado el cajero, etc.
                </p>
              </Grid>
              <Grid item xs={smallerDots?12:6}>
                <TableContainer>
                  <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.tableHeader}>BANCO</TableCell>
                          <TableCell className={classes.tableHeader} align="right">COMISIÓN</TableCell>
                        </TableRow>
                      </TableHead>
                  <TableBody>
                      <TableRow>
                        <TableCell className={classes.tableRow} component="th" scope="row">
                          ABC Capital
                        </TableCell>
                        <TableCell className={classes.tableRow} align="right">$ 15.00 (+IVA)</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.tableRow} component="th" scope="row">
                          Afirme
                        </TableCell>
                        <TableCell className={classes.tableRow} align="right">$ 30.00 (+IVA)</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.tableRow} component="th" scope="row">
                          Autofin
                        </TableCell>
                        <TableCell className={classes.tableRow} align="right">$ 20.00</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.tableRow} component="th" scope="row">
                          Azteca
                        </TableCell>
                        <TableCell className={classes.tableRow} align="right">$ 20.00 - $30.00</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.tableRow} component="th" scope="row">
                          BBVA
                        </TableCell>
                        <TableCell className={classes.tableRow} align="right">$ 29.50 (+IVA)</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.tableRow} component="th" scope="row">
                          Banamex
                        </TableCell>
                        <TableCell className={classes.tableRow} align="right">$ 26.50 (+ IVA)</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.tableRow} component="th" scope="row">
                          Bancoppel
                        </TableCell>
                        <TableCell className={classes.tableRow} align="right">$ 12.93</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.tableRow} component="th" scope="row">
                          Banjercito
                        </TableCell>
                        <TableCell className={classes.tableRow} align="right">$ 16.00 (+ IVA)</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.tableRow} component="th" scope="row">
                          Banorte
                        </TableCell>
                        <TableCell className={classes.tableRow} align="right">$ 27.00 (+ IVA)</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.tableRow} component="th" scope="row">
                          Banregio
                        </TableCell>
                        <TableCell className={classes.tableRow} align="right"> $ 25.00 (+ IVA)</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.tableRow} component="th" scope="row">
                          Bansefi
                        </TableCell>
                        <TableCell className={classes.tableRow} align="right">$ 35.00 (+ IVA)</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.tableRow} component="th" scope="row">
                          Bansi
                        </TableCell>
                        <TableCell className={classes.tableRow} align="right">$ 35.00 (+ IVA)</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.tableRow} component="th" scope="row">
                          CI Banco
                        </TableCell>
                        <TableCell className={classes.tableRow} align="right">$ 25.00 (+ IVA)</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.tableRow} component="th" scope="row">
                          Compartamos
                        </TableCell>
                        <TableCell className={classes.tableRow} align="right">$ 20.00 (+ IVA)</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.tableRow} component="th" scope="row">
                          HSBC
                        </TableCell>
                        <TableCell className={classes.tableRow} align="right">$ 30.90 (+ IVA)</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.tableRow} component="th" scope="row">
                          Inbursa
                        </TableCell>
                        <TableCell className={classes.tableRow} align="right">$ 15.00 (+ IVA)</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.tableRow} component="th" scope="row">
                          Interbanco
                        </TableCell>
                        <TableCell className={classes.tableRow} align="right">$ 17.40 - $ 34.80</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.tableRow} component="th" scope="row">
                          Mifel
                        </TableCell>
                        <TableCell className={classes.tableRow} align="right">$ 40.00</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.tableRow} component="th" scope="row">
                          Multiva
                        </TableCell>
                        <TableCell className={classes.tableRow} align="right">$ 40.00 (+ IVA)</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.tableRow} component="th" scope="row">
                          Santander
                        </TableCell>
                        <TableCell className={classes.tableRow} align="right">$ 30.00 (+ IVA)</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.tableRow} component="th" scope="row">
                          Scotiabank
                        </TableCell>
                        <TableCell className={classes.tableRow} align="right">$ 30.00 (+ IVA)</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.tableRow} component="th" scope="row">
                          Ve por más
                        </TableCell>
                        <TableCell className={classes.tableRow} align="right">$ 20.00 (+ IVA)</TableCell>
                      </TableRow>
                  </TableBody>
                </Table>
                </TableContainer>
              </Grid>
          </Grid>
        </Box>
    );
}

export default withStyles(styles)(Withdrawals);
